import httpClient from "./httpClient";
import cronHttpClient from "./cronHttpClient";

const MANUAL_CRON_JOB = "/manual-cron-job";

const getAllManualCrons = () =>
  cronHttpClient.get(`${MANUAL_CRON_JOB}/all-manual-crons`);

const triggerManualCronJob = (job) =>
  cronHttpClient.get(`${MANUAL_CRON_JOB}/${job}`);

const triggerFanmetricSpotifyManualCronJob = (flag, date, uuid) =>
  httpClient.get(
    `${MANUAL_CRON_JOB}/fanmetric-spotify/${flag}/${date}/${uuid}`
  );

const triggerTrackReleasesStatsManualCronJob = (flag, date, uuid) =>
  httpClient.get(
    `${MANUAL_CRON_JOB}/track-releases-stats/${flag}/${date}/${uuid}`
  );

const getManualCronStatusByFlagIfTriggered = (flag, date, uuid) =>
  httpClient.get(`${MANUAL_CRON_JOB}/status/${flag}/${date}/${uuid}`);

export {
  getAllManualCrons,
  triggerManualCronJob,
  triggerFanmetricSpotifyManualCronJob,
  triggerTrackReleasesStatsManualCronJob,
  getManualCronStatusByFlagIfTriggered,
};
