<template>
  <CardToolbar
    class="cron-jobs"
    title="Schedulers"
    :description="null"
    :show-toolbar-buttons="true"
    :padding="false"
  >
    <template v-slot:toolbar>
      <div class="refresh-icon">
        <el-icon @click="getAllCronJobs">
          <Refresh :color="'#009EF7'" />
        </el-icon>
      </div>
    </template>
    <template v-slot:body>
      <el-table
        style="width: 100%"
        row-key="id"
        :fit="true"
        table-layout="fixed"
        stripe
        header-cell-class-name="table-header-text"
        :data="jobs"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
        v-loading="loading"
      >
        <el-table-column label="Job Name">
          <template #default="scope">
            {{ scope.row.title ? scope.row.title : "" }}
          </template>
        </el-table-column>
        <el-table-column label="Last Update">
          <template #default="scope">
            {{
              scope.row.lastCompletedAt
                ? moment(scope.row.lastCompletedAt).format("MMM DD, YYYY")
                : "Not scraped yet"
            }}
          </template>
        </el-table-column>
        <el-table-column label="Scraping">
          <template #default="scope">
            {{ scope.row.scraping ? "True" : "False" }}
          </template>
        </el-table-column>
        <el-table-column label="Failed">
          <template #default="scope">
            {{ scope.row.flag ? "No" : "Yes" }}
          </template>
        </el-table-column>
        <el-table-column label="Action">
          <template #default="scope">
            <el-button
              class="loading-icon"
              v-if="scope.row.scraping"
              type="primary"
              size="small"
              loading
              >Scraping
            </el-button>
            <el-button
              v-else
              type="primary"
              size="small"
              @click="triggerJobs(scope.row)"
              >Scrape
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </CardToolbar>
</template>
<script>
import { onMounted, ref } from "vue";
import CardToolbar from "@/common/components/CardToolbar";
import moment from "moment";
import {
  getAllManualCrons,
  getManualCronStatusByFlagIfTriggered,
  triggerFanmetricSpotifyManualCronJob, triggerManualCronJob,
  triggerTrackReleasesStatsManualCronJob,
} from "@/api/mongoCrons";
import { getAuth } from "@firebase/auth";
import { Refresh } from "@element-plus/icons-vue";

export default {
  name: "Schedulers",
  components: {
    Refresh,
    CardToolbar,
  },
  setup() {
    const loading = ref(false);
    const jobs = ref([]);
    const uid = ref("");
    const date = moment().format("YYYY-MM-DD");
    onMounted(() => {
      getAllCronJobs();
    });

    const getAllCronJobs = async () => {
      try {
        loading.value = true;
        const { data } = await getAllManualCrons();
        jobs.value = data;
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const triggerJobs = async (job) => {
      try {
        loading.value = true;
        const { data } = await triggerManualCronJob(job.route);
        await getAllCronJobs()
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const getJobStatus = async (job) => {
      try {
        const { data } = await getManualCronStatusByFlagIfTriggered(
          job.flag,
          date,
          uid.value
        );
        job["status"] = data;
        job.scraping = true;
      } catch (e) {
        console.log(e);
      }
    };
    const tableRowClassName = ({ rowIndex }) => {
      return rowIndex % 2 === 0
        ? "table-data-text"
        : "table-data-text striped-table-row";
    };
    const tableCellClassName = ({ columnIndex }) => {
      if (columnIndex === 0 || columnIndex === 1) {
        return "table-column-ellipses";
      } else {
        return "";
      }
    };
    return {
      loading,
      jobs,
      moment,
      tableRowClassName,
      tableCellClassName,
      triggerJobs,
      getAllCronJobs,
    };
  },
};
</script>
<style lang="scss">
.loading-icon {
  .el-icon-loading {
    color: #ffffff !important;
  }
}

.refresh-icon {
  .el-icon {
    font-size: 24px;
  }
}
</style>
